
export const loadSideBar = (selectedTag) => {
    if(selectedTag === undefined) {
        selectedTag = '';
    }

    if(!window.localStorage.getItem('selectedTag') && window.localStorage.getItem('selectedTag') !== '') {
        saveSelectedTag(selectedTag);
        return true;
    } else if (window.localStorage.getItem('selectedTag') !== selectedTag) {
        saveSelectedTag(selectedTag);
        return true;
    }

    return false
}

export const saveSelectedTag = (selectedTag) => {
    if(selectedTag === undefined) {
        selectedTag = '';
    }
    window.localStorage.setItem("selectedTag", selectedTag)
}

export const refactorCreateContactTags = (tags) => {
    let newTags = [];

    let splittedTags = tags.split(', ');
    splittedTags.map((splittedTag) => {
        let splittedTags2 = splittedTag.split(',');
        splittedTags2.map((splittedTag2) => {
            if(splittedTag2) {
                newTags.push({ tag: splittedTag2, score: 0 })
            }
        })
    })

    return newTags;
}

export const createTagString = (tags) => {
    let tagString = '';
    tags.map(( tag, tagIndex ) => {
        tagString += tag.tag;

        if( tagIndex < ( tag.length - 1 ) ) {
            tagString += ', ';
        }
    })

    return tagString;
}