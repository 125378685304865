import * as React from "react"
import Layout from "../../Layout/layout";
import {Link, navigate} from "gatsby";
import TimeAgo from "timeago-react";
import _uniqueId from 'lodash/uniqueId';
import Axios from "axios";
import {getAccessToken} from "../../../services/auth";
import {useEffect, useState} from "react";

import '../Listing/contacts.css'
import {connect} from "react-redux";
import ContactAddSocialRow from "./contactAddSocialRow";
import { Form, Button} from "react-bootstrap";
import {
    notificationFail,
    notificationProcessing,
    notificationSuccess
} from "../../../redux/reducers/notification/notificationHelper";
import {
    PRIVACY_PUBLIC,
    CONTACT_PHONE_OPTIONS,
    CONTACT_ADDRESS_OPTIONS,
    DEFAULT_CONTACT_PHONE_OPTION,
    DEFAULT_CONTACT_ADDRESS_OPTION
} from "../../../services/globalStaticData";
import {refactorCreateContactTags} from "../../../services/tagsHelper";
import validator from 'validator'

const ContactAdd = ({ selectedContactId, dispatch }) => {
    const
        [ selectedPhoneLabel, setSelectedPhoneLabel ] = useState(DEFAULT_CONTACT_PHONE_OPTION.label),
        [ selectedPhoneIcon, setSelectedPhoneIcon ] = useState(DEFAULT_CONTACT_PHONE_OPTION.icon),
        [ selectedPhoneType, setSelectedPhoneType ] = useState(DEFAULT_CONTACT_PHONE_OPTION.type),

        [ selectedAddressLabel, setSelectedAddressLabel ] = useState(DEFAULT_CONTACT_ADDRESS_OPTION.label),
        [ selectedAddressType, setSelectedAddressType ] = useState(DEFAULT_CONTACT_ADDRESS_OPTION.type),

        [ socialLinkRows, setSocialLinkRows ] = useState(['contact_add_data_row_0']),

        [ formError, setFormError ] = useState(''),
        [ nameError, setNameError ] = useState(''),
        [ emailErrors, setEmailErrors ] = useState([]),
        [ phoneErrors, setPhoneErrors ] = useState([]),
        [ addressErrors, setAddressErrors ] = useState([]),
        [ socialLinkErrors, setSocialLinkErrors ] = useState([])
    ;

    const submitContact = (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);

        let data = {
            name : formData.get('name'),
            email : formData.get('email'),
            companyName : formData.get('companyName'),
            jobTitle : formData.get('jobTitle'),
            note : formData.get('note'),
            tags: [],
            phone : {
                type: selectedPhoneType,
                phone: formData.get('phone'),
                permission: PRIVACY_PUBLIC
            },
            address : {
                type: selectedAddressType,
                address: formData.get('address'),
                permission: PRIVACY_PUBLIC
            },
            socialLinks : []
        }


        if(formData.get('tags')) {
            data.tags = refactorCreateContactTags(formData.get('tags'))
        }

        socialLinkRows.map((socialLinkRow) => {
            if(formData.get(`socialLinks[${socialLinkRow}][url]`)) {
                data.socialLinks.push({
                    type: formData.get(`socialLinks[${socialLinkRow}][type]`),
                    url: formData.get(`socialLinks[${socialLinkRow}][url]`),
                    permission: PRIVACY_PUBLIC
                })
            }
        })

        setTimeout(() => {}, 300)
        validateAndSaveContact(data);
    }

    const validateAndSaveContact = (data) => {
        let error = false;
        if(!data.name) {
            setNameError('Please Enter Contact Name');
            error = true;
        }
        if(!validator.isEmail(data.email)) {
            setEmailErrors(['Please Enter Valid Email']);
            error = true;
        }

        if(data.phone.phone) {
            let re = /\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
            if(!re.test(data.phone.phone)) {
                error = true;
                setPhoneErrors(['Please Enter Valida Phone Number with country code.'])
            }
        }

        if(!error) {
            setTimeout(() => {}, 300)
            saveContact(data);
        }
    }

    const saveContact = (data) => {
        notificationProcessing(dispatch);
        clearErrorMessages();

        return new Promise(async () => {
            await Axios.post(
                `${process.env.GATSBY_BACKEND_API_URL}/contact/add`,
                data,
                {
                    headers: {
                        'Authorization': 'Bearer ' + getAccessToken()
                    }
                }
            ).then(function (response) {
                if(response.status === 201) {
                    notificationSuccess(dispatch);
                    navigate('/contacts/detail/' + response.data.contact)
                    return;
                }
            }).catch(function (error) {
                if(error.response.status === 400) {
                    setErrorMessages(error.response.data)
                } else if (error.response.data)  {
                    setFormError(error.response.data.detail)
                }
                notificationFail(dispatch);
            });
        });
    }

    const clearErrorMessages = () => {
        setFormError('')
        setNameError('')
        setEmailErrors([])
        setPhoneErrors([])
        setAddressErrors([])
    }

    const setErrorMessages = (data) => {
        if(data.name) {
            setNameError(data.name);
        }

        if(data.email) {
            setEmailErrors([data.email]);
        }

        if(data.phone) {
            let phoneErrors = [];
            if(data.phone.permission) {
                phoneErrors.push(data.phone.permission)
            }
            if(data.phone.phone) {
                phoneErrors.push(data.phone.phone)
            }
            if(data.phone.type) {
                phoneErrors.push(data.phone.type)
            }
            setPhoneErrors(phoneErrors);
        }


        if(data.socialLinks.length > 0) {
            let tmpSocialLinkErrors = [];
            data.socialLinks.map((link, linkIndex) => {
                let tmpSocialLinkError = [];
                if(link.permission) {
                    tmpSocialLinkError.push(link.permission)
                }
                if(link.url) {
                    tmpSocialLinkError.push(link.url)
                }
                if(link.type) {
                    tmpSocialLinkError.push(link.type)
                }

                tmpSocialLinkErrors[linkIndex] = tmpSocialLinkError;
            });
            setSocialLinkErrors(tmpSocialLinkErrors);
        }
    }

    useEffect(() => {})

  return (
      <Layout>
          <section className="mainSection addContactContainer">
            <Form onSubmit={submitContact} id={"contactForm"}>
              <div className="container-fluid">
                  <div className="row g-0">
                      <div className="col">
                          <div className="row">
                              <div className="col-lg-12">
                                  <h1 className="text-center mb-4 mt-3">
                                      <i className="bi bi-person-plus-fill" /> Add Contact
                                  </h1>
                              </div>

                                  <div className="col-lg-3">
                                      <div className="mb-3">
                                          <Form.Control
                                              type="text"
                                              className="form-control"
                                              placeholder="Contact Name"
                                              name='name'
                                          />
                                      </div>
                                      { nameError && <div className="mb-3 error-text">{ nameError }</div> }

                                      <div className="mb-3">
                                          <Form.Control
                                              type="email"
                                              className="form-control"
                                              placeholder="Email"
                                              name={"email"}
                                          />
                                      </div>
                                      { emailErrors && <React.Fragment>
                                          {emailErrors.map( (emailError) => <span className="mb-3 error-text">{ emailError }</span>)}
                                      </React.Fragment> }

                                      <div className="input-group mb-3">
                                          <div className="dropdown addNewPhoneBox">
                                              <button className="btn btn-default dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                  <i className={selectedPhoneIcon}/>
                                                  <span>{selectedPhoneLabel}</span>
                                              </button>
                                              <ul className="dropdown-menu">
                                                  { CONTACT_PHONE_OPTIONS.map(( phoneData, phoneDataIndex) => {
                                                      return (
                                                          <li key={phoneDataIndex}
                                                              onClick={ () => {
                                                              setSelectedPhoneIcon(phoneData.icon)
                                                              setSelectedPhoneLabel(phoneData.label)
                                                              setSelectedPhoneType(phoneData.type)
                                                          }}>
                                                              <a className="dropdown-item" href="#">
                                                                  <i className={phoneData.icon} /> {phoneData.label}
                                                              </a>
                                                          </li>
                                                      )
                                                  })}
                                              </ul>
                                          </div>
                                          <Form.Control
                                              type="text"
                                              className="form-control"
                                              placeholder={DEFAULT_CONTACT_PHONE_OPTION.placeHolder}
                                              name="phone"
                                          />
                                      </div>

                                      { phoneErrors && <React.Fragment>
                                          {phoneErrors.map( (phoneError) => <div className="mb-3 error-text">{ phoneError }</div>)}
                                      </React.Fragment> }

                                      <div className="input-group mb-3">
                                          <div className="dropdown addNewPhoneBox">
                                              <button className="btn btn-default dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                  <i className="bi bi-house-door-fill" />
                                                  <span>{selectedAddressLabel}</span>
                                              </button>
                                              <ul className="dropdown-menu">
                                                  { CONTACT_ADDRESS_OPTIONS.map(( addressData, addressDataIndex) => {
                                                      return (
                                                          <li key={addressDataIndex}
                                                              onClick={ () => {
                                                                  setSelectedAddressLabel(addressData.label)
                                                                  setSelectedAddressType(addressData.type)
                                                          }}>
                                                              <a className="dropdown-item" href="#">
                                                                  <i className={addressData.icon} /> {addressData.label}
                                                              </a>
                                                          </li>
                                                      )
                                                  })}
                                              </ul>
                                          </div>
                                          <Form.Control
                                              type="text"
                                              className="form-control"
                                              placeholder={DEFAULT_CONTACT_ADDRESS_OPTION.placeHolder}
                                              name="address"
                                          />
                                      </div>
                                  </div>
                              { addressErrors && <React.Fragment>
                                  {addressErrors.map( (addressError) => <div className="mb-3 error-text">{ addressError }</div>)}
                              </React.Fragment> }

                                  <div className="col-lg-3">
                                      <div className="mb-3">
                                          <Form.Control
                                              type="text"
                                              className="form-control"
                                              id="contact_tag"
                                              placeholder="Tag"
                                              data-toggle="tagsinput"
                                              name="tags"
                                          />
                                      </div>

                                      { socialLinkRows.map( ( rowId, loopIndex) => {
                                          return (
                                              <ContactAddSocialRow
                                                  key={rowId}
                                                  rowId={rowId}
                                                  socialLinkRows={socialLinkRows}
                                                  setSocialLinkRows={setSocialLinkRows}
                                                  socialLinkErrors={socialLinkErrors}
                                                  loopIndex={loopIndex}
                                              />
                                          );
                                      })}

                                      <div className="mb-3 addNewContactSocialContainer">
                                          <div className="form-floating">
                                              <textarea
                                                  className="form-control"
                                                  placeholder="Notes"
                                                  id="Notes"
                                                  style={{ height: '100px' }}
                                                  name="note"
                                              />
                                              <label htmlFor="Notes">Notes</label>
                                          </div>
                                      </div>
                                  </div>
                                  <div className="col-lg-3">
                                      <div className="mb-3">
                                          <Form.Control
                                              type="text"
                                              className="form-control"
                                              placeholder="Company Name"
                                              name="companyName"
                                          />
                                      </div>
                                      <div className="mb-3">
                                          <Form.Control
                                              type="text"
                                              className="form-control"
                                              placeholder="Job Title"
                                              name="jobTitle"
                                          />
                                      </div>
                                  </div>
                                  <div className="col-lg-3">
                                  <div className="mb-3 text-center">
                                      <div className="addNewContactAvatar d-inline-block">
                                          <img
                                              src={`${process.env.GATSBY_WRANGLER_URL+"StaticImages/avatar.gif"}`}
                                              alt="Contact avatar"
                                              className="contact_pic"
                                          />
                                          <a className="btnEditAvatar">
                                              <span className="d-block" data-bs-toggle="tooltip"
                                                    title="Contact avatar">
                                                  <i className="bi bi-pencil-fill" />
                                              </span>
                                          </a>
                                      </div>
                                  </div>
                              </div>
                          </div>

                          {
                              formError &&
                              <div className="row">
                                  <div className="col-lg-12 text-center">
                                      <div className="col-lg-12">
                                          { formError }
                                      </div>
                                  </div>
                              </div>
                          }

                          <div className="row">
                              <div className="col-lg-12 text-center">
                                  <Button className="btn btn-primary btn-lg" type={"submit"}>
                                      <i className="bi bi-check-lg" /> Save
                                  </Button>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
            </Form>
          </section>
      </Layout>

  )
}

export default connect(state => {
    return {...state}
})(ContactAdd)



