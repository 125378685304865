import * as React from "react";
import PrivateRoute from "../../../components/privateRoute";
import ContactAdd from "../../../components/Contacts/Add/contactAdd";
import ReduxWrapper from "../../../redux/reduxWrapper";


const Contact = ( props ) => {
    return (
        <PrivateRoute
            component={ContactAdd}
            location={props.location}
            selectedContactId={props.id}
        />
    )
}

const WrappedPage = (props) => (
    <ReduxWrapper element={<Contact {...props} />} />
)
export default WrappedPage

